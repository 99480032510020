/**
 * 请求相关常量
 */

export const RequestCodeEnum = {
  // 成功
  SUCCESS: 200,
  // 失败
  FAIL: 500,
  // token过期
  TOKEN_EXPIRE: 401,
}
