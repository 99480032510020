import { defineComponent as _defineComponent } from 'vue'
import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "chat-ai" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "btn-right" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "mask-box" }
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]
const _hoisted_11 = ["poster", "src"]
const _hoisted_12 = ["src"]
const _hoisted_13 = ["src"]
const _hoisted_14 = ["src"]
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "top-box" }
const _hoisted_17 = { class: "bottom-box" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = ["src"]

import {nextTick, ref, onMounted} from 'vue'
import {getChatStreamApi, getVoiceToCharacterUrlApi} from '@/api'
import router from "@/router";
import {TTSRecorder} from './js/index'
import {Base64} from 'js-base64'
import TypeIt from 'typeit'

const ossBaseUrl = 'https://ch-file.oss-cn-zhangjiakou.aliyuncs.com/universe/'

export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

/* eslint-disable */
let ttsRecorder = new TTSRecorder({
  speed: 50,
  voice: 50,
  pitch: 50,
  voiceName: 'aisjiuxu',
  vcn: 'aisjiuxu',
  appId: 'c6c2f3f1',
  text: '',
  tte: 'UTF8',
  defaultText: '请输入您要合成的文本',
})
const activeIndex = ref(0)
const maskIsopen = ref(true)
const myvideo2 = ref()
const dzhIsoepn = ref(true)
const chatData = ref([])
const myvideo = ref()
const audioUrl = ref('home/dahzaohu.mp3')
const myAudio = ref()
const chatBtn = ref(false)
const myvideo3 = ref()
const myvideo4 = ref()
const myvideo5 = ref()
const keydownIsopen = ref(true)
let sourceList: EventSource[] = []
let btnStatus = "UNDEFINED"
// eslint-disable-next-line no-undef
const recorder = new RecorderManager("./dist");
const step = ref(1)
const textData = ref(null)
recorder.onStart = () => {
  changeBtnStatus("OPEN");
}
const isopen = ref(false)
let iatWS: any;
let resultText = "";
let resultTextTemp = "";
let countdownInterval: any;
let source: any = null;
// 用时间戳模拟登录用户
let userId: string | number;
const typeIts = ref(null);
const resultTextData = ref('')
const loginIsopen = ref(false)
const messageStr = ref('')
const problemList = ref([
  {
    name: '山西博物院藏有多少文物？'
  },
  {
    name: '山西出名的博物馆有哪些?'
  },
  {
    name: '鸟尊造型有什么特点?'
  },
  {
    name: '山西大同有哪些美食?'
  },
  {
    name: '山西有多少家国保单位?'
  }
])
const ok = () => {
  step.value = 3
  myvideo3.value.play()
  maskIsopen.value = false
  getWebSocketUrl()
  sourceFuc(`https://digitalhuman.chwhyun.com/api/keyue/ernie/connect/${userId}`)
}

// 这里为接入语音--------------------------------------
/**
 * 获取websocket url
 * 该接口需要后端提供，这里为了方便前端处理
 */
const getWebSocketUrl = async () => {
  const res = await getVoiceToCharacterUrlApi()
  return res.data
}

const toBase64 = (buffer) => {
  let binary = '';
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]); // 将字节转换为字符
  }
  return window.btoa(binary); // 将字符转换为 Base64 字符串
}

const countdown = () => {
  let seconds = 60;
  nextTick(() => {
    chatBtn.value = true
    // btnControl.innerText = `录音中（${seconds}s）`;
    countdownInterval = setInterval(() => {
      seconds = seconds - 1;
      if (seconds <= 0) {
        clearInterval(countdownInterval);
        recorder.stop();
        chatBtn.value = false
      } else {
        chatBtn.value = true
        // btnControl.innerText = `录音中（${seconds}s）`;
      }
    }, 1000);
  })
}

const changeBtnStatus = (status) => {
  nextTick(() => {
    btnStatus = status;
    if (status === "CONNECTING") {
      console.log("建立连接中")
      // btnControl.innerText = "建立连接中";
      // document.getElementById("result").innerText = "";
      resultText = "";
      resultTextTemp = "";
    } else if (status === "OPEN") {
      countdown();
    } else if (status === "CLOSING") {
      // btnControl.innerText = "关闭连接中";
    } else if (status === "CLOSED") {
      // chatBtn.value = true
      // btnControl.innerText = "开始录音";
    }
  })
}

const renderResult = (resultData) => {
  // 识别结束
  let jsonData = JSON.parse(resultData);
  if (jsonData.data && jsonData.data.result) {
    let data = jsonData.data.result;
    let str = "";
    let ws = data.ws;
    for (let i = 0; i < ws.length; i++) {
      str = str + ws[i].cw[0].w;
    }
    // 开启wpgs会有此字段(前提：在控制台开通动态修正功能)
    // 取值为 "apd"时表示该片结果是追加到前面的最终结果；取值为"rpl" 时表示替换前面的部分结果，替换范围为rg字段
    if (data.pgs) {
      if (data.pgs === "apd") {
        // 将resultTextTemp同步给resultText
        resultText = resultTextTemp;
      }
      // 将结果存储在resultTextTemp中
      resultTextTemp = resultText + str;
    } else {
      resultText = resultText + str;
      resultTextData.value = resultText
    }
    // document.getElementById("result").innerText =
    //     resultTextTemp || resultText || "";
  }
  if (jsonData.code === 0 && jsonData.data.status === 2) {
    iatWS.close();
  }
  if (jsonData.code !== 0) {
    iatWS.close();
    console.error(jsonData);
  }
}

const connectWebSocket = async () => {
  const websocketUrl = await getWebSocketUrl();
  if ("WebSocket" in window) {
    iatWS = new WebSocket(websocketUrl);
  } else if ("MozWebSocket" in window) {
    // eslint-disable-next-line no-undef
    iatWS = new MozWebSocket(websocketUrl);
  } else {
    alert("浏览器不支持WebSocket");
    return;
  }
  changeBtnStatus("CONNECTING");
  iatWS.onopen = (e) => {
    // 开始录音
    recorder.start({
      sampleRate: 16000,
      frameSize: 1280,
    });
    let params = {
      common: {
        app_id: 'c6c2f3f1',
      },
      business: {
        language: "zh_cn",
        domain: "iat",
        accent: "mandarin",
        vad_eos: 5000,
        dwa: "wpgs",
      },
      data: {
        status: 0,
        format: "audio/L16;rate=16000",
        encoding: "raw",
      },
    };
    iatWS.send(JSON.stringify(params));
  };
  iatWS.onmessage = (e) => {
    renderResult(e.data);
  };
  iatWS.onerror = (e) => {
    recorder.stop();
    changeBtnStatus("CLOSED");
  };
  iatWS.onclose = (e) => {
    recorder.stop();
    changeBtnStatus("CLOSED");
    // sourceFuc(`https://digitalhuman.chwhyun.com/api/keyue/ernie/chatStream/${userId}?message=${resultTextData.value}`)
    sourceFuc(`https://digitalhuman.chwhyun.com/api/keyue/ernie/chatStream/${userId}?message=${resultTextData.value}`)
  };
}

recorder.onFrameRecorded = ({isLastFrame, frameBuffer}) => {
  if (iatWS.readyState === iatWS.OPEN) {
    iatWS.send(
        JSON.stringify({
          data: {
            status: isLastFrame ? 2 : 1,
            format: "audio/L16;rate=16000",
            encoding: "raw",
            audio: toBase64(frameBuffer),
          },
        })
    );
    if (isLastFrame) {
      changeBtnStatus("CLOSING");
    }
  }
};
recorder.onStop = () => {
  clearInterval(countdownInterval);
};

const btnControlClick = async () => {
  // step.value = 2
  // myvideo2.value.play()
  // // messageStr.value = ''
  // if (btnStatus === "UNDEFINED" || btnStatus === "CLOSED") {
  //   messageStr.value = ''
  //   // new TypeIt('#textData', {
  //   //   speed: 190,
  //   //   startDelay: 900,
  //   // }).delete();
  //   chatBtn.value = true
  //   ttsRecorder.stop()
  //   await connectWebSocket();
  // } else if (btnStatus === "CONNECTING" || btnStatus === "OPEN") {
  //   // 结束录音
  //   // chatBtn.value = false
  //   // await recorder.stop();
  //   // isopen.value = true
  //   // // const res = await getChatStreamApi(userId, '你是谁')
  //   // console.log(resultTextData.value,'uuuuuuuuuuuuuuuuuu')
  //   // sourceFuc(`https://digitalhuman.chwhyun.com/api/keyue/ernie/chatStream/${userId}?message=${resultTextData.value}`)
  // }
}

const sourceFuc = (url) => {
  if (window.EventSource) {
    // 建立连接
    source = new EventSource(url);
    sourceList.push(source)
    /**
     * 连接一旦建立，就会触发open事件
     * 另一种写法：source.onopen = function (event) {}
     */
    source.addEventListener('open', function (e) {
      console.log("建立连接。。。ooooo")
    }, false);
    /**
     * 客户端收到服务器发来的数据
     * 另一种写法：source.onmessage = function (event) {}
     */
    source.addEventListener('message', function (e) {
      let valueText = JSON.parse(e.data)
      messageStr.value += valueText.finalResult.replace(/<\/br>/g, '')
      if (valueText.status === 'done') {
        let stringWithoutSearchString = messageStr.value.replace("done", "");
        ttsRecorder.setParams({
          text: stringWithoutSearchString
        })
        ljhc()
      } else {
        // step.value = 3
        if (myvideo3.value.paused) {
          myvideo3.value.play()
        }
        setMessageInnerHTML(valueText.finalResult);
        console.log("字符串中不包含'done'");
      }
      // console.log(messageStr.value)
    });
    /**
     * 如果发生通信错误（比如连接中断），就会触发error事件
     * 或者：
     * 另一种写法：source.onerror = function (event) {}
     */
    source.addEventListener('error', function (e) {
      if (e.readyState === EventSource.CLOSED) {
        setMessageInnerHTML("连接关闭");
        chatBtn.value = false
      } else {
      }
    }, false);
  } else {
    console.log("你的浏览器不支持SSE")
  }
}
const sourceCloseFun = () => {
  for (let i in sourceList) {
    sourceList[i].close();
  }
}
// 关闭Sse连接
const closeSse = () => {
  source?.close();
  const httpRequest = new XMLHttpRequest();
  httpRequest.open('GET', '/sse/close/' + userId, true);
  httpRequest.send();
  console.log("close");
}

// 将消息显示在网页上
const setMessageInnerHTML = (innerHTML) => {
  if (chatData.value[chatData.value.length - 1]) {
    chatData.value[chatData.value.length - 1].chat += innerHTML;
  }
}

// 监听窗口关闭事件，主动去关闭sse连接，如果服务端设置永不过期，浏览器关闭后手动清理服务端数据
window.onbeforeunload = function () {
  closeSse();
};
// 语音结束--------------------------------------
// 字转语音
ttsRecorder.onWillStatusChange = function (oldStatus, status) {
  if (oldStatus === "play" && status === 'endPlay') {
    step.value = 1
    if (resultTextData.value.includes('你有什么功能') || resultTextData.value.includes('你能干什么')) {
      isopen.value = false
    } else {
      isopen.value = true
    }
    myvideo.value.play()
    myvideo3.value.pause()
    sourceCloseFun()
  }
  if (oldStatus === "endPlay" && status === 'init') {
    loginIsopen.value = false
    if (resultTextData.value.includes('你好') || resultTextData.value.includes('您好')) {
      step.value = 4
      if (myvideo4.value.paused) {
        myvideo4.value.currentTime = 0
        myvideo4.value.play()
      }
    } else if (resultTextData.value.includes('再见')) {
      step.value = 5
      if (myvideo5.value.paused) {
        myvideo5.value.currentTime = 0
        myvideo5.value.play()
      }
    } else {
      step.value = 3
      if (resultTextData.value.includes('你有什么功能') || resultTextData.value.includes('你能干什么')) {
        isopen.value = false
      } else {
        isopen.value = true
      }
      // isopen.value = !resultTextData.value.includes('你有什么功能');
      if (myvideo3.value.paused) {
        myvideo3.value.play()
      }
    }
  }
  // 可以在这里进行页面中一些交互逻辑处理：按钮交互等
  // 按钮中的文字
  // let btnState = {
  //   init: '立即合成',
  //   ttsing: '正在合成',
  //   play: '停止播放',
  //   endPlay: '重新播放',
  //   errorTTS: '合成失败',
  // }
}
const ljhc = () => {
  if (['init', 'endPlay', 'errorTTS'].indexOf(ttsRecorder.status) > -1) {
    ttsRecorder.start()
  } else {
    ttsRecorder.stop()
  }
}
const funClick = (val: number) => {
  if (val == 1) {
    document.documentElement.requestFullscreen();
  } else if (val == 2) {
    location.reload();
  }
}
//语音播报

onMounted(() => {
  userId = new Date().getTime();
  // nextTick(() => {
  //   btnControl = document.getElementById("btn_control");
  // })
  // 添加键盘按下事件监听器
  document.addEventListener("keydown", function (event) {
    if (keydownIsopen.value) {
      if (!loginIsopen.value) {
        step.value = 2
        if (myvideo2.value) {
          myvideo2.value.play()
        }
        messageStr.value = ''
        chatBtn.value = true
        ttsRecorder.stop()
        connectWebSocket();
        loginIsopen.value = true
        keydownIsopen.value = false
      }
    }
  });
  // 添加键盘放开事件监听器
  document.addEventListener("keyup", function (event) {
    console.log("您按下的按钮的keyCode为：" + event.keyCode)
    keydownIsopen.value = true
    chatBtn.value = false
    isopen.value = true
    recorder.stop();
    // 结束录音
    // const res = getChatStreamApi(userId, resultTextData.value)
  });
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "chat-btn",
        onClick: btnControlClick
      }, [
        _withDirectives(_createElementVNode("img", {
          src: ossBaseUrl + (chatBtn.value ? 'home2/audioimg.gif' : 'home2/shuohua.png'),
          alt: ""
        }, null, 8, _hoisted_3), [
          [_vShow, !loginIsopen.value]
        ]),
        _withDirectives(_createElementVNode("img", {
          src: ossBaseUrl + 'home2/audioimgover.png',
          alt: ""
        }, null, 8, _hoisted_4), [
          [_vShow, loginIsopen.value]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("img", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (funClick(1))),
        src: ossBaseUrl + 'home2/quanpin.png',
        alt: ""
      }, null, 8, _hoisted_6),
      _createElementVNode("img", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (funClick(2))),
        src: ossBaseUrl + 'home2/shuaxin.png',
        alt: ""
      }, null, 8, _hoisted_7)
    ]),
    _createElementVNode("div", {
      class: "mask",
      style: _normalizeStyle({opacity:maskIsopen.value ? 1 : 0,pointerEvents:maskIsopen.value ? 'auto' : 'none'})
    }, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("img", {
          class: "maskbgi",
          src: ossBaseUrl + 'home/welcomeMaks.png',
          alt: ""
        }, null, 8, _hoisted_9),
        _createElementVNode("img", {
          class: "btn",
          onClick: ok,
          src: ossBaseUrl + 'home/djty2.png',
          alt: ""
        }, null, 8, _hoisted_10)
      ])
    ], 4),
    _createElementVNode("video", {
      ref_key: "myvideo",
      ref: myvideo,
      poster: ossBaseUrl + 'home2/yyzrkbgi.png',
      loop: "",
      playsinline: "",
      style: _normalizeStyle({zIndex:step.value == 1 ? 1 : 0}),
      src: ossBaseUrl + 'home2/dazhaohu.mp4'
    }, null, 12, _hoisted_11),
    _createElementVNode("video", {
      ref_key: "myvideo2",
      ref: myvideo2,
      loop: "",
      playsinline: "",
      style: _normalizeStyle({zIndex:step.value == 2 ? 1 : 0}),
      src: ossBaseUrl + 'home2/lingtin.mp4'
    }, null, 12, _hoisted_12),
    _createElementVNode("video", {
      ref_key: "myvideo3",
      ref: myvideo3,
      loop: "",
      playsinline: "",
      style: _normalizeStyle({zIndex:step.value == 3 ? 1 : 0}),
      src: ossBaseUrl + 'home2/huida.mp4'
    }, null, 12, _hoisted_13),
    _createElementVNode("video", {
      ref_key: "myvideo4",
      ref: myvideo4,
      loop: "",
      playsinline: "",
      style: _normalizeStyle({zIndex:step.value == 4 ? 1 : 0}),
      src: ossBaseUrl + 'home2/nihao.mp4'
    }, null, 12, _hoisted_14),
    _createElementVNode("video", {
      ref_key: "myvideo5",
      ref: myvideo5,
      loop: "",
      playsinline: "",
      style: _normalizeStyle({zIndex:step.value == 5 ? 1 : 0}),
      src: ossBaseUrl + 'home2/zaijian.mp4'
    }, null, 12, _hoisted_15),
    _createElementVNode("div", _hoisted_16, _toDisplayString(resultTextData.value ? resultTextData.value : '可以说“你好”来唤醒我'), 1),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", {
        style: _normalizeStyle({visibility: !isopen.value ? 'block' : 'hidden'}),
        class: "title"
      }, "可以试着问我", 4),
      _createElementVNode("div", {
        style: _normalizeStyle([{opacity: !isopen.value ? 1 : 0}, {"height":"8%"}])
      }, null, 4),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(problemList.value, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          style: _normalizeStyle({opacity: !isopen.value ? 1 : 0}),
          class: "problem",
          onClick: ($event: any) => (_ctx.listClick(item)),
          key: index
        }, _toDisplayString(item.name), 13, _hoisted_18))
      }), 128)),
      _createElementVNode("img", {
        class: "logings",
        style: _normalizeStyle({opacity: loginIsopen.value ? 1 : 0}),
        src: ossBaseUrl + 'home2/AIlogding.png',
        alt: ""
      }, null, 12, _hoisted_19),
      _createElementVNode("div", {
        style: _normalizeStyle({opacity: isopen.value ? 1 : 0}),
        class: "problem-box"
      }, _toDisplayString(messageStr.value), 5)
    ])
  ]))
}
}

})