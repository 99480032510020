import { Axios } from 'axios'
import axios from 'axios'
import { RequestCodeEnum } from '@/api/requestEnum'
import {baseUrl} from '@/config'

const methods = ['delete', 'get', 'head', 'options']
methods.forEach(function (method) {
  Axios.prototype[method] = function (url, params, config) {
    return this.request({
      ...(config || {}),
      method: method,
      url: url,
      params: params || {},
    })
  }
})

const instance = axios.create({
  baseURL: baseUrl,
  // timeout: 10 * 1000,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  // 请求元数据
  meta: {
    // 是否携带token
    withToken: false,
    // 是否返回默认的响应
    isReturnDefaultResponse: false,
    // 是否需要对返回数据进行处理
    isTransformResponse: true,
  },
})

// 请求拦截器
instance.interceptors.request.use(
  config => {
    if (config.meta.withToken) {
      config.headers.Authorization = 'token'
    }
    return config
  },
  error => Promise.reject(error)
)

// 响应拦截器
instance.interceptors.response.use(
  response => {
    const { isReturnDefaultResponse, isTransformResponse } = response.config.meta

    if (isReturnDefaultResponse) {
      return response
    }

    const data = response.data
    if (!isTransformResponse) {
      return data
    }

    if (response.config.method === 'download') {
      return data
    }
    // const { code, result, message } = data
    const { code, message } = data

    switch (code) {
      case RequestCodeEnum.SUCCESS:
        return data
      case RequestCodeEnum.FAIL:
        console.error(message)
        break
      case RequestCodeEnum.TOKEN_EXPIRE:
        console.error(message)
        break
      default:
        console.error(message)
    }
    return Promise.reject(response)
  },
  error => {
    return Promise.reject(error)
  }
)

export default instance
