<script setup lang="ts">
/* eslint-disable */
import {nextTick, ref, onMounted} from 'vue'
import {getChatStreamApi, getVoiceToCharacterUrlApi} from '@/api'
import router from "@/router";
import {TTSRecorder} from './js/index'
import {Base64} from 'js-base64'
import TypeIt from 'typeit'

let ttsRecorder = new TTSRecorder({
  speed: 50,
  voice: 50,
  pitch: 50,
  voiceName: 'aisjiuxu',
  vcn: 'aisjiuxu',
  appId: 'c6c2f3f1',
  text: '',
  tte: 'UTF8',
  defaultText: '请输入您要合成的文本',
})
const ossBaseUrl = 'https://ch-file.oss-cn-zhangjiakou.aliyuncs.com/universe/'
const activeIndex = ref(0)
const maskIsopen = ref(true)
const myvideo2 = ref()
const dzhIsoepn = ref(true)
const chatData = ref([])
const myvideo = ref()
const audioUrl = ref('home/dahzaohu.mp3')
const myAudio = ref()
const chatBtn = ref(false)
const myvideo3 = ref()
const myvideo4 = ref()
const myvideo5 = ref()
const keydownIsopen = ref(true)
let sourceList: EventSource[] = []
let btnStatus = "UNDEFINED"
// eslint-disable-next-line no-undef
const recorder = new RecorderManager("./dist");
const step = ref(1)
const textData = ref(null)
recorder.onStart = () => {
  changeBtnStatus("OPEN");
}
const isopen = ref(false)
let iatWS: any;
let resultText = "";
let resultTextTemp = "";
let countdownInterval: any;
let source: any = null;
// 用时间戳模拟登录用户
let userId: string | number;
const typeIts = ref(null);
const resultTextData = ref('')
const loginIsopen = ref(false)
const messageStr = ref('')
const problemList = ref([
  {
    name: '山西博物院藏有多少文物？'
  },
  {
    name: '山西出名的博物馆有哪些?'
  },
  {
    name: '鸟尊造型有什么特点?'
  },
  {
    name: '山西大同有哪些美食?'
  },
  {
    name: '山西有多少家国保单位?'
  }
])
const ok = () => {
  step.value = 3
  myvideo3.value.play()
  maskIsopen.value = false
  getWebSocketUrl()
  sourceFuc(`https://digitalhuman.chwhyun.com/api/keyue/ernie/connect/${userId}`)
}

// 这里为接入语音--------------------------------------
/**
 * 获取websocket url
 * 该接口需要后端提供，这里为了方便前端处理
 */
const getWebSocketUrl = async () => {
  const res = await getVoiceToCharacterUrlApi()
  return res.data
}

const toBase64 = (buffer) => {
  let binary = '';
  let bytes = new Uint8Array(buffer);
  let len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]); // 将字节转换为字符
  }
  return window.btoa(binary); // 将字符转换为 Base64 字符串
}

const countdown = () => {
  let seconds = 60;
  nextTick(() => {
    chatBtn.value = true
    // btnControl.innerText = `录音中（${seconds}s）`;
    countdownInterval = setInterval(() => {
      seconds = seconds - 1;
      if (seconds <= 0) {
        clearInterval(countdownInterval);
        recorder.stop();
        chatBtn.value = false
      } else {
        chatBtn.value = true
        // btnControl.innerText = `录音中（${seconds}s）`;
      }
    }, 1000);
  })
}

const changeBtnStatus = (status) => {
  nextTick(() => {
    btnStatus = status;
    if (status === "CONNECTING") {
      console.log("建立连接中")
      // btnControl.innerText = "建立连接中";
      // document.getElementById("result").innerText = "";
      resultText = "";
      resultTextTemp = "";
    } else if (status === "OPEN") {
      countdown();
    } else if (status === "CLOSING") {
      // btnControl.innerText = "关闭连接中";
    } else if (status === "CLOSED") {
      // chatBtn.value = true
      // btnControl.innerText = "开始录音";
    }
  })
}

const renderResult = (resultData) => {
  // 识别结束
  let jsonData = JSON.parse(resultData);
  if (jsonData.data && jsonData.data.result) {
    let data = jsonData.data.result;
    let str = "";
    let ws = data.ws;
    for (let i = 0; i < ws.length; i++) {
      str = str + ws[i].cw[0].w;
    }
    // 开启wpgs会有此字段(前提：在控制台开通动态修正功能)
    // 取值为 "apd"时表示该片结果是追加到前面的最终结果；取值为"rpl" 时表示替换前面的部分结果，替换范围为rg字段
    if (data.pgs) {
      if (data.pgs === "apd") {
        // 将resultTextTemp同步给resultText
        resultText = resultTextTemp;
      }
      // 将结果存储在resultTextTemp中
      resultTextTemp = resultText + str;
    } else {
      resultText = resultText + str;
      resultTextData.value = resultText
    }
    // document.getElementById("result").innerText =
    //     resultTextTemp || resultText || "";
  }
  if (jsonData.code === 0 && jsonData.data.status === 2) {
    iatWS.close();
  }
  if (jsonData.code !== 0) {
    iatWS.close();
    console.error(jsonData);
  }
}

const connectWebSocket = async () => {
  const websocketUrl = await getWebSocketUrl();
  if ("WebSocket" in window) {
    iatWS = new WebSocket(websocketUrl);
  } else if ("MozWebSocket" in window) {
    // eslint-disable-next-line no-undef
    iatWS = new MozWebSocket(websocketUrl);
  } else {
    alert("浏览器不支持WebSocket");
    return;
  }
  changeBtnStatus("CONNECTING");
  iatWS.onopen = (e) => {
    // 开始录音
    recorder.start({
      sampleRate: 16000,
      frameSize: 1280,
    });
    let params = {
      common: {
        app_id: 'c6c2f3f1',
      },
      business: {
        language: "zh_cn",
        domain: "iat",
        accent: "mandarin",
        vad_eos: 5000,
        dwa: "wpgs",
      },
      data: {
        status: 0,
        format: "audio/L16;rate=16000",
        encoding: "raw",
      },
    };
    iatWS.send(JSON.stringify(params));
  };
  iatWS.onmessage = (e) => {
    renderResult(e.data);
  };
  iatWS.onerror = (e) => {
    recorder.stop();
    changeBtnStatus("CLOSED");
  };
  iatWS.onclose = (e) => {
    recorder.stop();
    changeBtnStatus("CLOSED");
    // sourceFuc(`https://digitalhuman.chwhyun.com/api/keyue/ernie/chatStream/${userId}?message=${resultTextData.value}`)
    sourceFuc(`https://digitalhuman.chwhyun.com/api/keyue/ernie/chatStream/${userId}?message=${resultTextData.value}`)
  };
}

recorder.onFrameRecorded = ({isLastFrame, frameBuffer}) => {
  if (iatWS.readyState === iatWS.OPEN) {
    iatWS.send(
        JSON.stringify({
          data: {
            status: isLastFrame ? 2 : 1,
            format: "audio/L16;rate=16000",
            encoding: "raw",
            audio: toBase64(frameBuffer),
          },
        })
    );
    if (isLastFrame) {
      changeBtnStatus("CLOSING");
    }
  }
};
recorder.onStop = () => {
  clearInterval(countdownInterval);
};

const btnControlClick = async () => {
  // step.value = 2
  // myvideo2.value.play()
  // // messageStr.value = ''
  // if (btnStatus === "UNDEFINED" || btnStatus === "CLOSED") {
  //   messageStr.value = ''
  //   // new TypeIt('#textData', {
  //   //   speed: 190,
  //   //   startDelay: 900,
  //   // }).delete();
  //   chatBtn.value = true
  //   ttsRecorder.stop()
  //   await connectWebSocket();
  // } else if (btnStatus === "CONNECTING" || btnStatus === "OPEN") {
  //   // 结束录音
  //   // chatBtn.value = false
  //   // await recorder.stop();
  //   // isopen.value = true
  //   // // const res = await getChatStreamApi(userId, '你是谁')
  //   // console.log(resultTextData.value,'uuuuuuuuuuuuuuuuuu')
  //   // sourceFuc(`https://digitalhuman.chwhyun.com/api/keyue/ernie/chatStream/${userId}?message=${resultTextData.value}`)
  // }
}

const sourceFuc = (url) => {
  if (window.EventSource) {
    // 建立连接
    source = new EventSource(url);
    sourceList.push(source)
    /**
     * 连接一旦建立，就会触发open事件
     * 另一种写法：source.onopen = function (event) {}
     */
    source.addEventListener('open', function (e) {
      console.log("建立连接。。。ooooo")
    }, false);
    /**
     * 客户端收到服务器发来的数据
     * 另一种写法：source.onmessage = function (event) {}
     */
    source.addEventListener('message', function (e) {
      let valueText = JSON.parse(e.data)
      messageStr.value += valueText.finalResult.replace(/<\/br>/g, '')
      if (valueText.status === 'done') {
        let stringWithoutSearchString = messageStr.value.replace("done", "");
        ttsRecorder.setParams({
          text: stringWithoutSearchString
        })
        ljhc()
      } else {
        // step.value = 3
        if (myvideo3.value.paused) {
          myvideo3.value.play()
        }
        setMessageInnerHTML(valueText.finalResult);
        console.log("字符串中不包含'done'");
      }
      // console.log(messageStr.value)
    });
    /**
     * 如果发生通信错误（比如连接中断），就会触发error事件
     * 或者：
     * 另一种写法：source.onerror = function (event) {}
     */
    source.addEventListener('error', function (e) {
      if (e.readyState === EventSource.CLOSED) {
        setMessageInnerHTML("连接关闭");
        chatBtn.value = false
      } else {
      }
    }, false);
  } else {
    console.log("你的浏览器不支持SSE")
  }
}
const sourceCloseFun = () => {
  for (let i in sourceList) {
    sourceList[i].close();
  }
}
// 关闭Sse连接
const closeSse = () => {
  source?.close();
  const httpRequest = new XMLHttpRequest();
  httpRequest.open('GET', '/sse/close/' + userId, true);
  httpRequest.send();
  console.log("close");
}

// 将消息显示在网页上
const setMessageInnerHTML = (innerHTML) => {
  if (chatData.value[chatData.value.length - 1]) {
    chatData.value[chatData.value.length - 1].chat += innerHTML;
  }
}

// 监听窗口关闭事件，主动去关闭sse连接，如果服务端设置永不过期，浏览器关闭后手动清理服务端数据
window.onbeforeunload = function () {
  closeSse();
};
// 语音结束--------------------------------------
// 字转语音
ttsRecorder.onWillStatusChange = function (oldStatus, status) {
  if (oldStatus === "play" && status === 'endPlay') {
    step.value = 1
    if (resultTextData.value.includes('你有什么功能') || resultTextData.value.includes('你能干什么')) {
      isopen.value = false
    } else {
      isopen.value = true
    }
    myvideo.value.play()
    myvideo3.value.pause()
    sourceCloseFun()
  }
  if (oldStatus === "endPlay" && status === 'init') {
    loginIsopen.value = false
    if (resultTextData.value.includes('你好') || resultTextData.value.includes('您好')) {
      step.value = 4
      if (myvideo4.value.paused) {
        myvideo4.value.currentTime = 0
        myvideo4.value.play()
      }
    } else if (resultTextData.value.includes('再见')) {
      step.value = 5
      if (myvideo5.value.paused) {
        myvideo5.value.currentTime = 0
        myvideo5.value.play()
      }
    } else {
      step.value = 3
      if (resultTextData.value.includes('你有什么功能') || resultTextData.value.includes('你能干什么')) {
        isopen.value = false
      } else {
        isopen.value = true
      }
      // isopen.value = !resultTextData.value.includes('你有什么功能');
      if (myvideo3.value.paused) {
        myvideo3.value.play()
      }
    }
  }
  // 可以在这里进行页面中一些交互逻辑处理：按钮交互等
  // 按钮中的文字
  // let btnState = {
  //   init: '立即合成',
  //   ttsing: '正在合成',
  //   play: '停止播放',
  //   endPlay: '重新播放',
  //   errorTTS: '合成失败',
  // }
}
const ljhc = () => {
  if (['init', 'endPlay', 'errorTTS'].indexOf(ttsRecorder.status) > -1) {
    ttsRecorder.start()
  } else {
    ttsRecorder.stop()
  }
}
const funClick = (val: number) => {
  if (val == 1) {
    document.documentElement.requestFullscreen();
  } else if (val == 2) {
    location.reload();
  }
}
//语音播报

onMounted(() => {
  userId = new Date().getTime();
  // nextTick(() => {
  //   btnControl = document.getElementById("btn_control");
  // })
  // 添加键盘按下事件监听器
  document.addEventListener("keydown", function (event) {
    if (keydownIsopen.value) {
      if (!loginIsopen.value) {
        step.value = 2
        if (myvideo2.value) {
          myvideo2.value.play()
        }
        messageStr.value = ''
        chatBtn.value = true
        ttsRecorder.stop()
        connectWebSocket();
        loginIsopen.value = true
        keydownIsopen.value = false
      }
    }
  });
  // 添加键盘放开事件监听器
  document.addEventListener("keyup", function (event) {
    console.log("您按下的按钮的keyCode为：" + event.keyCode)
    keydownIsopen.value = true
    chatBtn.value = false
    isopen.value = true
    recorder.stop();
    // 结束录音
    // const res = getChatStreamApi(userId, resultTextData.value)
  });
})
</script>

<template>
  <div class="container">
    <div class="chat-ai">
      <div class="chat-btn" @click="btnControlClick">
        <img v-show="!loginIsopen" :src="ossBaseUrl + (chatBtn ? 'home2/audioimg.gif' : 'home2/shuohua.png')" alt="">
        <img v-show="loginIsopen" :src="ossBaseUrl + 'home2/audioimgover.png'" alt="">
      </div>
    </div>
    <div class="btn-right">
      <img @click="funClick(1)" :src="ossBaseUrl + 'home2/quanpin.png'" alt="">
      <img @click="funClick(2)" :src="ossBaseUrl + 'home2/shuaxin.png'" alt="">
    </div>
    <div class="mask" :style="{opacity:maskIsopen ? 1 : 0,pointerEvents:maskIsopen ? 'auto' : 'none'}">
      <div class="mask-box">
        <img class="maskbgi" :src="ossBaseUrl + 'home/welcomeMaks.png'" alt="">
        <img class="btn" @click="ok" :src="ossBaseUrl + 'home/djty2.png'" alt="">
      </div>
    </div>
    <video ref="myvideo" :poster="ossBaseUrl + 'home2/yyzrkbgi.png'" loop playsinline
           :style="{zIndex:step == 1 ? 1 : 0}"
           :src="ossBaseUrl + 'home2/dazhaohu.mp4'"/>
    <video ref="myvideo2" loop playsinline :style="{zIndex:step == 2 ? 1 : 0}"
           :src="ossBaseUrl + 'home2/lingtin.mp4'"/>
    <video ref="myvideo3" loop playsinline :style="{zIndex:step == 3 ? 1 : 0}"
           :src="ossBaseUrl + 'home2/huida.mp4'"/>

    <video ref="myvideo4" loop playsinline :style="{zIndex:step == 4 ? 1 : 0}"
           :src="ossBaseUrl + 'home2/nihao.mp4'"/>
    <video ref="myvideo5" loop playsinline :style="{zIndex:step == 5 ? 1 : 0}"
           :src="ossBaseUrl + 'home2/zaijian.mp4'"/>

    <div class="top-box">{{ resultTextData ? resultTextData : '可以说“你好”来唤醒我' }}</div>
    <!--    <input class="top-box" :value="messageStr ? messageStr : '可以说“你好，晋依依”来唤醒我'" type="text">-->
    <div class="bottom-box">
      <div :style="{visibility: !isopen ? 'block' : 'hidden'}" class="title">可以试着问我</div>
      <div :style="{opacity: !isopen ? 1 : 0}" style="height: 8%;"></div>
      <div :style="{opacity: !isopen ? 1 : 0}" class="problem" v-for="(item,index) in problemList"
           @click="listClick(item)" :key="index">{{ item.name }}
      </div>
      <img class="logings" :style="{opacity: loginIsopen ? 1 : 0}" :src="ossBaseUrl + 'home2/AIlogding.png'" alt="">
      <!--      id="textData" ref="textData"-->
      <div :style="{opacity: isopen ? 1 : 0}" class="problem-box">{{ messageStr }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .bottom-box {
    width: 80%;
    //height: 28%;
    padding-bottom: 3%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10vh;
    font-size: 4vw;
    text-align: center;
    color: #860000;
    font-weight: 900;
    z-index: 10;
    background-image: url('https://ch-file.oss-cn-zhangjiakou.aliyuncs.com/universe/home2/bottombgimg.png');
    background-size: 100% 100%;

    .logings {
      position: absolute;
      width: 23%;
      left: 50%;
      transform: translateX(-50%);
      top: 40%;
      z-index: 90;
    }

    .title {
      text-align: left;
      color: #860000;
      font-weight: 900;
      font-size: 2vh;
      padding-left: 10%;
      margin-top: 2%;
    }

    .problem {
      width: 80%;
      color: #860000;
      font-weight: 400;
      font-size: 2vh;
      padding: 1% 3%;
      margin: 0 auto;
      background-image: url('https://ch-file.oss-cn-zhangjiakou.aliyuncs.com/universe/home2/problembox.png');
      background-size: 100% 100%;
      margin-top: 1.5%;
      pointer-events: none;
    }

    .problem-box {
      position: absolute;
      left: 50%;
      top: 14%;
      transform: translateX(-50%);
      width: 80%;
      height: 68%;
      overflow-y: auto;
      color: #860000;
      font-weight: 400;
      font-size: 3.5vw;
      text-align: justify;
      padding: 3%;
      background-image: url('https://ch-file.oss-cn-zhangjiakou.aliyuncs.com/universe/home2/kuangimg.png');
      background-size: 100% 100%;
      margin-top: 1.5%;
      z-index: 20;
    }
  }

  .top-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 2vh;
    font-size: 4vw;
    text-align: center;
    color: #860000;
    border: 0;
    pointer-events: none;
    background-color: transparent;
    font-weight: 900;
    z-index: 10;
    background-image: url('https://ch-file.oss-cn-zhangjiakou.aliyuncs.com/universe/home2/topbgimg.png');
    background-size: 100% 100%;
    box-sizing: border-box;
  }

  .btn-right {
    position: absolute;
    left: 4%;
    bottom: 0%;
    width: 7%;
    z-index: 100;

    img {
      width: 100%;
      margin-bottom: 1vh;
    }
  }

  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 90;
    background-color: rgba(0, 0, 0, .5);
    transition: 1s opacity;

    .mask-box {
      position: absolute;
      width: 76vw;
      height: 38vh;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      padding: 0 5%;
      box-sizing: border-box;
      text-align: center;

      .maskbgi {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      p {
        display: inline-block;
        position: relative;
        width: 45%;
        font-size: 4vw;
        margin-top: 20%;
        color: #2E6FB5;
        margin-bottom: 40%;
      }

      .btn {
        display: inline-block;
        position: absolute;
        width: 33%;
        left: 18%;
        top: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }

  .bgimg {
    width: 100%;
    height: 100%;
  }

  .return {
    position: absolute;
    left: 6vw;
    top: 4vh;
    width: 15vw;
    font-size: 4.5vw;
    color: #fff;
    z-index: 20;
  }

  .box {
    position: absolute;
    width: 40vw;
    top: 25vh;
    right: 2vw;
    transition: 1s opacity;
    z-index: 10;

    img {
      width: 100%;
      height: 100%;
    }

    span {
      position: absolute;
      color: #2E6FB5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      line-height: 10.7vh;
      text-align: center;
      font-size: 3.2vw;
    }
  }

  .boxs {
    position: absolute;
    left: 10vw;
    top: 19vh;
    width: 56vw;
    height: 77vh;
    z-index: 15;
  }

  .tag-list {
    position: absolute;
    perspective: 1000px;
    width: 40vw;
    top: 20vh;
    right: 2vw;
    height: 5.5vh;
    box-sizing: border-box;
    transition: 1s opacity;
    padding: 0 2% 2%;
    overflow: hidden;
    opacity: 0;
    z-index: 10;

    .bgi {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 30vh;
    }

    .tag-text {
      position: relative;
      width: 100%;
      height: 5vh;
      margin-bottom: 0.5vh;
      transform: rotateX(90deg);
      //transform-origin: center bottom;
      opacity: 0;

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }

      div {
        position: relative;
        text-align: center;
        line-height: 4.8vh;
        font-size: 3.5vw;
        color: #fff;
      }
    }
  }

  .info {
    transition: 1s opacity;
    z-index: 10;

    .title {
      position: absolute;
      width: 80vw;
      height: 5vh;
      top: 12vh;
      left: 50%;
      transform: translate(-50%);

      img {
        width: 100%;
        height: 100%;
      }

      span {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        color: #2E6FB5;
        font-size: 3.8vw;
        text-align: center;
        line-height: 5.5vh;
      }
    }

    .info-box {
      position: absolute;
      right: 2vw;
      top: 22vh;
      width: 55vw;
      height: 65vh;
      padding: 5% 8%;
      box-sizing: border-box;
      z-index: 10;

      > img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

      .text {
        position: relative;
        text-align: justify;
        text-indent: 2em;
        font-size: 3.5vw;
        letter-spacing: 0.2vw;
        margin-bottom: 2vh;
        color: #2E6FB5;
      }

      .img {
        position: relative;
        width: 100%;
        height: 10vh;
        margin-bottom: 5.5vh;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .button {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 1vh;
        width: 70%;
        height: 6vh;

        img {
          width: 100%;
        }
      }
    }
  }

  .business {
    position: absolute;
    right: 2vw;
    top: 22vh;
    width: 55vw;
    height: 10vh;
    padding: 23% 3% 5%;
    box-sizing: border-box;
    overflow: hidden;
    z-index: 10;

    .info-box {
      img {
        &:nth-child(2) {
          margin-top: 4.8vh;
        }
      }

      .bgi {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 52vh;
      }

      .text {
        position: relative;
        text-align: justify;
        text-indent: 2em;
        font-size: 3.5vw;
        letter-spacing: 0.2vw;
        margin-bottom: 2vh;
        color: #2E6FB5;
      }

      .img {
        position: relative;
        width: 100%;
        height: 10vh;
        margin-bottom: 5.5vh;

        img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .button {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 1vh;
        width: 70%;
        height: 10vh;

        img {
          width: 100%;
        }
      }
    }

    .tags {
      position: relative;
      display: block;
      width: 87%;
      height: auto;
      transition: .5s all;
      margin: 0 auto;
      margin-top: 1.5vh;
      opacity: 0;
      transform-origin: center bottom;
    }
  }

  .chat-ai {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60vh;
    z-index: 20;
    box-sizing: border-box;
    padding: 0 3vh;
    transition: 1s opacity;

    .chat-box {
      width: 100%;
      height: 70%;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .left {
      width: 100%;
      margin: 3vh 0;

      img {
        display: inline-block;
        width: 7vw;
        height: 7vw;
        border-radius: 50%;
        vertical-align: top;
        margin-right: 3vw;
        background-color: #fff;
      }

      div {
        position: relative;
        //display: inline-block;
        //width: 60vw;
        //padding: 2% 3%;
        //font-size: 2vw;
        //box-sizing: border-box;
        //border: 1px solid #D39C42;
        //background-color: RGBA(226, 209, 191, .5);
        //border-radius: 15px;
        //color: #fff;
        display: inline-block;
        width: 55vw;
        min-height: 5vh;
        padding: 2% 3%;
        font-size: 3vw;
        background-color: rgba(226, 209, 191);
        box-shadow: -10px 15px 30px rgba(0, 0, 0, .2);
        -moz-border-radius: 10px;
        -webkit-border-radius: 10px;
        border-radius: 10px;
        margin-left: 4vw;

        &:before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-right: 16px solid #e2d1bf;
          border-bottom: 10px solid transparent;
          margin: 0 0 0 -6.5vw;
        }
      }
    }

    .right {
      text-align: right;

      img {
        margin-right: 0;
        margin-left: 3vw;
      }

      div {
        color: #6B503D;
        text-align: left !important;
        margin-right: 3vw;
        box-shadow: 10px 15px 30px rgba(0, 0, 0, .2);
        margin-left: 0;

        &:before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-right: 16px solid #e2d1bf;
          border-bottom: 10px solid transparent;
          margin: 0 0 0 57vw;
          transform: rotateY(180deg);
        }
      }
    }

    .chat-btn {
      position: absolute;
      bottom: 34vh;
      left: 75%;
      transform: translateX(-50%);
      width: 13vw;

      .audio-box {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-45%) translateY(-58%);
        width: 10vw;
        height: 65%;
        white-space: nowrap;
        display: flex;
        align-items: center;

        > div {
          background-color: #fff;
          border-radius: 20px;
          width: 12%;
          margin-right: 8%;

          &:nth-child(1) {
            height: 45%;
            animation: audioList1 1s -0.1s infinite alternate;
          }

          &:nth-child(2) {
            height: 60%;
            animation: audioList2 1s -0.2s infinite alternate;
          }

          &:nth-child(3) {
            height: 85%;
            animation: audioList3 1s -0.3s infinite alternate;
          }

          &:nth-child(4) {
            height: 60%;
            animation: audioList4 1s -0.4s infinite alternate;
          }

          &:nth-child(5) {
            height: 45%;
            margin-right: 0;
            animation: audioList5 1s -0.5s infinite alternate;
          }
        }

        @keyframes audioList1 {
          0% {
            height: 10%;
          }
          100% {
            height: 45%;
          }
        }
        @keyframes audioList2 {
          0% {
            height: 10%;
          }
          100% {
            height: 60%;
          }
        }
        @keyframes audioList3 {
          0% {
            height: 10%;
          }
          100% {
            height: 85%;
          }
        }
        @keyframes audioList4 {
          0% {
            height: 10%;
          }
          100% {
            height: 60%;
          }
        }
        @keyframes audioList5 {
          0% {
            height: 10%;
          }
          100% {
            height: 45%;
          }
        }
      }

      img {
        width: 100%;
      }
    }
  }

  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    pointer-events: none;
    object-fit: cover;
  }

}

.tag-listAnimation {
  padding-top: 15% !important;
  animation: formAnimation 0.5s cubic-bezier(0.46, 0.73, 0.44, 0.76) forwards;
}

.tag-listAnimations {
  animation: formAnimations 1s cubic-bezier(0.46, 0.73, 0.44, 0.76) forwards;
}

.tagListAnimation {
  animation: 1s heightAnimation forwards;
}

.tagListAnimations {
  animation: 1s heightAnimations forwards;
}

@keyframes formAnimation {
  0% {
    height: 0;
  }
  100% {
    height: 30vh;
  }
}

@keyframes formAnimations {
  0% {
    height: 10vh;
  }
  100% {
    height: 75vh;
  }
}

@keyframes heightAnimation {
  0% {
    opacity: 0;
    transform: rotate3d(1, 0, 0, 95deg);
  }
  100% {
    opacity: 1;
    transform: rotate3d(0, 0, 0, 0deg);
  }
}

@keyframes heightAnimations {
  0% {
    opacity: 0;
    transform: rotate3d(1, 1, 1, 45deg);
  }
  100% {
    opacity: 1;
    transform: rotate3d(0, 0, 0, 0deg);
  }
}
</style>
