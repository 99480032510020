import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Homediv from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Homediv,
    meta: {
      keepAlive: true, // 将Home页面缓存
    },
  },
  {
    path: '/HomeDetile/:id',
    name: 'HomeDetile',
    component: () => import('../views/HomeDetile.vue'),
    meta: {
      keepAlive: false, // 不要缓存Detail页面
    },
  },
  {
    path: '/ContactMe',
    name: 'ContactMe',
    component: () => import('../views/ContactMe.vue'),
    meta: {
      keepAlive: false, // 不要缓存Detail页面
    },
  }
]

const router = createRouter({
  history:  createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
